import React, { useEffect, useState } from 'react'
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import ProjectCard from './ProjectCard'
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import {CategoryType, projectMembershipType, ProjectType, SkillType} from './Interfaces';
import {auth, db} from "../firebase"
import { Box, FormControl, InputLabel, MenuItem, Paper, Select, Tooltip, Typography } from '@mui/material';
import { collection, doc, getDocs, query, where } from 'firebase/firestore';
import { useGlobalState as useFirebaseGlobalState } from "./Settings" ;
import { useAuthState } from 'react-firebase-hooks/auth';

export default function Projects() {

  const [Projects, setProjects] = useState<ProjectType[]>();
  const [ProjectsMemberships, setProjectsMemberships] = useState<projectMembershipType[]>();
  const [FilteredProjects1, setFilteredProjects1] = useState<ProjectType[]>();
  const [FilteredProjects2, setFilteredProjects2] = useState<ProjectType[]>();
  const [FilteredProjects3, setFilteredProjects3] = useState<ProjectType[]>();

  const [categories, setCategories] = useState<CategoryType[]>([]);
  const [skills, setSkills] = useState<SkillType[]>([]);

  const [user, loading, error] = useAuthState(auth);
  let currentUserId = user?.uid;
  let currentUserName = user?.displayName??"";
  useEffect(() =>{
      currentUserId = user?.uid;
      currentUserName = user?.displayName??"";
  },
  [user]
  );

  const [useFirebase] = useFirebaseGlobalState('useFirebase');
  
  const [projectFilter, setprojectFilter] = 
  React.useState
  <(
    "AllPublicProjects"|
    "ProjectsYouOwn"|
    "ProjectsYouAreAMemberOf"
    )>("AllPublicProjects");

  const [CategoryFilter, setCategoryFilter] = useState<string>("AllCategories");
  const [SkillFilter, setSkillFilter] = useState<string>("AllSkills");

  const handleProjectFilterChange = (e:any) => {
    setprojectFilter(e.target.value);
  };

  const handleCategoryFilterChange = (e:any) => {
    setCategoryFilter(e.target.value);
  };

  const handleSkillFilterChange = (e:any) => {
    setSkillFilter(e.target.value);
  };
  

  React.useEffect(() =>{
      if(projectFilter === "AllPublicProjects")
      {
        setFilteredProjects1(
          Projects?.filter(project =>((project.visibility==="public") )));
      }   
      else if(projectFilter === "ProjectsYouOwn")
      {
        setFilteredProjects1(
          //Projects?.filter(project => (project.projectMembers?.find(member=>(member.owner===true))?.userId===currentUserId)) );
          Projects?.filter(project => (project.OwnerId == currentUserId)));
      }
      else if(projectFilter === "ProjectsYouAreAMemberOf")
      {
        setFilteredProjects1(
          Projects?.filter(project=> (ProjectsMemberships?.
            find(Membership=>
            ((Membership.userId===currentUserId) && 
            (Membership.status==="active") && 
            (Membership.projectId===project.id))))));
      }
  },
  [Projects, ProjectsMemberships, projectFilter, CategoryFilter, SkillFilter]
  );
  
  
  React.useEffect(() =>{
    if(CategoryFilter !== "AllCategories")
    {
      setFilteredProjects2(FilteredProjects1?.filter(project => project.category===CategoryFilter));
    }
    else
    {
      setFilteredProjects2(FilteredProjects1);
    }
  },
  [FilteredProjects1]
  );

  React.useEffect(() =>{
    if(SkillFilter !== "AllSkills")
    {
      setFilteredProjects3(FilteredProjects2?.filter(project=>(project?.skillList?.find(skill=>(skill === SkillFilter)))))
    }
    else
    {
      setFilteredProjects3(FilteredProjects2);
    }
  },
  [FilteredProjects2]
  );

  function loadDB()
  {
    const fetchProjects = async () => {
    const q = query(collection(db, "Projects")/*, where("visibility", "==", "public")*/);
    const querySnapshot = await getDocs(q);
      let Projects:ProjectType[]=[];
      querySnapshot.docs.forEach(doc=>{
        let Project:ProjectType;
        Project=doc.data();
        Project.id = doc.id;
        Projects.push(Project);
      })
      setProjects(Projects)
    }
    fetchProjects().catch(console.error);   

    const fetchProjectsMemberships = async () => {
      const q = query(collection(db, "ProjectsMemberships"), where("userId", "==", currentUserId));
      const querySnapshot = await getDocs(q);
        let projectsMemberships:projectMembershipType[]=[];
        querySnapshot.docs.forEach(doc=>{
          let projectMembership:projectMembershipType;
          projectMembership=doc.data();
          projectMembership.id = doc.id;
          projectsMemberships.push(projectMembership);
        })
        setProjectsMemberships(projectsMemberships)
      }
    fetchProjectsMemberships().catch(console.error);   

    const fetchCategories = async () => {
    const querySnapshot = await getDocs(collection(db, "Categories"));
      let Categories:CategoryType[]=[];
      querySnapshot.docs.forEach(doc=>{
        let Category:CategoryType;
        Category=doc.data();
        Category.id = doc.id;
        Categories.push(Category);
      })
      Categories.sort((a:CategoryType, b:CategoryType) => a.title?.localeCompare(b.title??"")??0);
      setCategories(Categories)
    }
    fetchCategories().catch(console.error);  

    const fetchSkills = async () => {
    const querySnapshot = await getDocs(collection(db, "Skills"));
        let Skills:SkillType[]=[];
        querySnapshot.docs.forEach(doc=>{
          let Skill:SkillType;
          Skill=doc.data();
          Skill.id = doc.id;
          Skills.push(Skill);
        })
        Skills.sort((a:SkillType, b:SkillType) => a.title?.localeCompare(b.title??"")??0);
        setSkills(Skills);
      }
      fetchSkills().catch(console.error);  
  }

  useEffect(()=>{
    loadDB();
  },[]);


  return (
<Container>

  <Button sx={{textTransform: 'none', fontSize:'20', m:2}}
  variant="outlined" component={(props:any) => <Link {...props} to="/Home/CreateProject" />}>Start a new Project</Button>

  <Paper elevation={0} 
    sx={{
        p:2,
        m:3,
        display: 'flex',
        flexDirection: 'row',
        justifyContent:'space-between',
        borderRadius: 5
    }}>
        <Box sx={{my:1, width:'30%'}}>
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Project Filter</InputLabel>
            <Select
            labelId="projectfilter-select-label"
            id="projectfilter-select"
            value={projectFilter||''}
            label="projectfilter"
            onChange={handleProjectFilterChange}         
            >
              <MenuItem value={"AllPublicProjects"}>All public projects</MenuItem>
              <MenuItem value={"ProjectsYouOwn"}>Projects you own</MenuItem>
              <MenuItem value={"ProjectsYouAreAMemberOf"}>Projects you are a member of</MenuItem>
            </Select>
        </FormControl>   
        </Box>

        <Box sx={{my:1, width:'30%'}}>
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Category Filter</InputLabel>
            <Select
            labelId="categoryfilter-select-label"
            id="categoryfilter-select"
            value={CategoryFilter||''}
            label="categoryfilter"
            onChange={handleCategoryFilterChange}         
            >
              <MenuItem value={"AllCategories"}>All Categories</MenuItem>
              {categories.map(category => <MenuItem value={category.title}>{category.title}</MenuItem>)}
            </Select>
        </FormControl>   
        </Box>

        <Box sx={{my:1, width:'30%'}}>
        <FormControl fullWidth>
            <InputLabel id="skill-simple-select-label">Skill Filter</InputLabel>
            <Select
            labelId="skillfilter-select-label"
            id="skillfilter-select"
            value={SkillFilter||''}
            label="Skillfilter"
            onChange={handleSkillFilterChange}         
            >
              <MenuItem value={"AllSkills"}>All Skills</MenuItem>
              {skills.map(skill => <MenuItem value={skill.title}>{skill.title}</MenuItem>)}
            </Select>
        </FormControl>   
        </Box>
  </Paper>
    
      <Grid container spacing={3}>
        {FilteredProjects3?.map(project => (
          <Grid item xs={12} md={6} lg={4} key={project.id}>
            <ProjectCard project={project} showButtons={true}/>
          </Grid>
        ))}
      </Grid>

      <br />


</Container>
  )
}
