import { Box, Button, Typography } from "@mui/material";
import useState from 'react-usestateref';
import { ErrorLogType, ReviewType, TaskType, TransactionType } from "./Interfaces";
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { auth, db } from "../firebase";
import { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import CompensationChart from "./CompensationChart";


export default function TaskConclude({Task, CashAssigned, SharesAssigned, ConcludeCallback}:{Task:TaskType, CashAssigned:number, SharesAssigned:number, ConcludeCallback:()=>void})
    {

    const [Reviews, setReviews, ReviewsRef] = useState<ReviewType[]>([]);
    const [user, loading, error] = useAuthState(auth);
    let currentUserId = user?.uid;
    let currentUserName = user?.displayName??"";
    const [newTransactions, setNewTransactions] = useState<TransactionType[]>([]);
    const [cashEarned, setcashEarned] = useState<number>(0);
    const [cashHeld, setcashHeld] = useState<number>(0);
    const [sharesEarned, setsharesEarned] = useState<number>(0);
    const [sharesHeld, setsharesHeld] = useState<number>(0);


    function AverageRating()
    {
        return Reviews.reduce((sum:number, value)=>{sum =sum+(value.Rating??0); return sum;}, 0)/(Reviews.length!==0 ? Reviews.length:1)*100/5;
    }

    useEffect(
        ()=>{
            if(Task.id)
            {
                loadDB();
            }
        },[Task]);


    useEffect(
        ()=>{
            if(CashAssigned>0 || SharesAssigned>0)
            UpdateTransaction();
        },[CashAssigned, SharesAssigned, Reviews]);

            
    function UpdateTransaction()
    {
        let cashEarnedl=Math.floor(CashAssigned*AverageRating()/100)
        let cashHeldl=CashAssigned-Math.floor(CashAssigned*AverageRating()/100)
        let sharesEarnedl=Math.floor(SharesAssigned*AverageRating()/100)
        let sharesHeldl=SharesAssigned-Math.floor(SharesAssigned*AverageRating()/100)

        setcashEarned(cashEarnedl);
        setcashHeld(cashHeldl);
        setsharesEarned(sharesEarnedl);
        setsharesHeld(sharesHeldl);

        let transactionList:TransactionType[]=[];
        if(cashEarnedl>0)
        {
            let cashTransaction:TransactionType = {} ;
            cashTransaction.type = "cash";
            cashTransaction.sourceType = "project";
            cashTransaction.sourceId = Task.id;
            cashTransaction.destinationType = "user";
            cashTransaction.destinationId = Task.assignee?.assigneeId;
            cashTransaction.status = "Pending";
            cashTransaction.value = cashEarnedl;
            cashTransaction.date = new Date();

            transactionList.push(cashTransaction);
        }

        if(sharesEarnedl>0)
        {
            let shareTransaction:TransactionType = {} ;
            shareTransaction.type = "equity";
            shareTransaction.sourceType = "project";
            shareTransaction.sourceId = Task.id;
            shareTransaction.destinationType = "user";
            shareTransaction.destinationId = Task.assignee?.assigneeId;
            //Share compensations do not require confirmation.
            shareTransaction.status = "Confirmed";
            shareTransaction.value = sharesEarnedl;
            shareTransaction.date = new Date();

            transactionList.push(shareTransaction);
        }
        setNewTransactions(transactionList);
    }

    function loadDB(){

        const fetchReviews = async () => {
            const q = query(collection(db, "Reviews"), where("targetId", "==", Task.id));
            const querySnapshot = await getDocs(q);
                let Reviews:ReviewType[]=[];
                querySnapshot.docs.forEach(doc=>{
                let Review:ReviewType;
                Review=doc.data() as ReviewType;
                Review.id = doc.id;
                Review.isDirty = false;
                Reviews.push(Review);
                })
                setReviews(Reviews);
            }
            fetchReviews().catch((e)=>{ErrorHandler(e, "Fetch Reviews")});            
    }

    async function saveDB(){

        newTransactions.forEach(
            async (transaction)=>{
                await addDoc(collection(db, "Transactions"), transaction ).catch((e)=>{ErrorHandler(e, "Add Transactions");}).then(ConcludeCallback);
            }
        )
        //ConcludeCallback();

    }

    async function ErrorHandler(error:Error, SourceFunction:string)
    {
      let errorLog:ErrorLogType = {file: "TaskConclude", message: error.message, userId:currentUserId??"", date:new Date(), sourceFunction: SourceFunction, }
      await addDoc(collection(db, "ErrorLogs"), errorLog );
      //setstatusText("We're sorry .. something went wrong.");    
    }



        return(
            
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems:'center'
                }}>
            <Typography sx={{color:'blue', fontSize:'min(4vw,25px)', pb:2}}>You are about to conclude this task</Typography>


            <CompensationChart cashEarned={cashEarned} cashHeld={cashHeld} sharesEarned={sharesEarned} sharesHeld={sharesHeld} />

            
            <Box >
                <Typography sx={{fontSize:'min(3.5vw,17px)', py:0.5}} display="inline">The task currently has an average rating of </Typography>
                <Typography sx={{fontSize:'min(3.5vw,18px)', py:0.5, fontWeight:'bold'}} display="inline">{AverageRating()}%</Typography>
                <Typography sx={{fontSize:'min(3.5vw,17px)', py:0.5}} display="inline">, this rating will translate to a cash compensation of </Typography>
                <Typography sx={{fontSize:'min(3.5vw,18px)', py:0.5, fontWeight:'bold'}} display="inline">{cashEarned}</Typography>
                <Typography sx={{fontSize:'min(3.5vw,18px)', py:0.5, fontWeight:'bold'}} display="inline"> EGP</Typography>
                <Typography sx={{fontSize:'min(3.5vw,17px)', py:0.5}} display="inline"> out of the  </Typography>
                <Typography sx={{fontSize:'min(3.5vw,18px)', py:0.5, fontWeight:'bold'}} display="inline">{CashAssigned}</Typography>
                <Typography sx={{fontSize:'min(3.5vw,18px)', py:0.5, fontWeight:'bold'}} display="inline"> EGP</Typography>
                <Typography sx={{fontSize:'min(3.5vw,17px)', py:0.5}} display="inline"> allocated to the task, and an Equity compesation of </Typography>
                <Typography sx={{fontSize:'min(3.5vw,18px)', py:0.5, fontWeight:'bold'}} display="inline">{sharesEarned}</Typography>
                <Typography sx={{fontSize:'min(3.5vw,18px)', py:0.5, fontWeight:'bold'}} display="inline"> Shares</Typography>
                <Typography sx={{fontSize:'min(3.5vw,17px)', py:0.5}} display="inline"> out of the  </Typography>
                <Typography sx={{fontSize:'min(3.5vw,18px)', py:0.5, fontWeight:'bold'}} display="inline">{SharesAssigned}</Typography>
                <Typography sx={{fontSize:'min(3.5vw,18px)', py:0.5, fontWeight:'bold'}} display="inline"> Shares</Typography>
                <Typography sx={{fontSize:'min(3.5vw,17px)', py:0.5}} display="inline"> allocated to the task.</Typography>

            </Box>
            <Button onClick={saveDB} sx={{textTransform: 'none', mt: 2, minWidth:'15%', fontSize:'min(4vw,15px)'}} variant="contained" >End Task and Transfer Compensation</Button>

            </Box>
            );
    }

