import { Box, IconButton, Paper, Slider, TextField, TextareaAutosize, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material";
import { useEffect } from "react";
import { ResponsiveCalendar } from '@nivo/calendar'
import Title from "./Title";
import { EffortLogType, ErrorLogType, TaskType } from "./Interfaces";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import DateAdapter from '@mui/lab/AdapterDateFns';
import { Add, Remove } from "@mui/icons-material";
import useState from 'react-usestateref';
import { addDoc, collection, doc, getDocs, query, setDoc, where } from "firebase/firestore";
import { auth, db } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { isDisabled } from "@testing-library/user-event/dist/utils";



export default function CalendarLog ({Task}:{Task:TaskType})
{
  const [EffortLogs, setEffortLogs, EffortLogsRef] = useState<EffortLogType[]>([]);
  const [SelectedDate, setSelectedDate] = useState<string>(formatDate(new Date().toUTCString()));
  const [statusText, setstatusText, setstatusTextRef] = useState<string>("");

  const [user, loading, error] = useAuthState(auth);
  let currentUserId = user?.uid;
  let currentUserName = user?.displayName??"";


  function CalendarClickHandler(event:any)
  {
    setSelectedDate(event.day);
  }

  function EnableDisableLog(event:any, newValue:any)
  {
    newValue=newValue===true?true:false;
    if(EffortLogs.find(log=>log.date===SelectedDate))
    {
      setEffortLogs(
        EffortLogs.map(log=>{
          if(log.date===SelectedDate)
          {
            log.enabled=newValue;
            log.isDirty=true;
            setstatusText("Saving..");
          }
          return log;
        })
      )
    }
    else
    {
      setEffortLogs([...EffortLogs, {taskId:Task.id, userId:currentUserId, date:SelectedDate, enabled:newValue, isDirty:true}])
    }
  }


  function changeHours(newValue:any)
  {
    if(EffortLogs.find(log=>log.date===SelectedDate)?.enabled===true)
    {
      setEffortLogs(
        EffortLogs.map(log=>{
          if(log.date===SelectedDate)
          {
            if(newValue>=0 && newValue<=24)
            {
              log.hours=newValue;
              log.isDirty=true;
              setstatusText("Saving..");  
            }
          }
          return log;
        })
      )
    }
  }

  function changeDescription(event:any)
  {
    if(EffortLogs.find(log=>log.date===SelectedDate)?.enabled===true)
    {
      setEffortLogs(
        EffortLogs.map(log=>{
          if(log.date===SelectedDate)
          {
            log.description=event.target.value;
            log.isDirty=true;
            setstatusText("Saving..");
          }
          return log;
        })
      )
    }
  }

  function formatDate(date:string) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }


    const [data, setData] = useState<{value:number, day:string}[]>([])

    useEffect(()=>{
      console.log("data",data);
    },[data]);


    useEffect(()=>{
      console.log("SelectedDate",SelectedDate);
    },[SelectedDate]);




    useEffect(()=>{
      console.log("EffortLogs", EffortLogs);

      let results:{value:number, day:string}[]=[];
      EffortLogs.forEach(
        (log) =>
        {
          if(log.enabled===true)
          {results.push({value:log.hours!, day:log.date!})}
        }
      )
      setData(results);
    },[EffortLogs]);


    useEffect(
      ()=>{
          loadDB();
          const interval = setInterval(() => {
                saveDB();
          },2*1000);
          return () => clearInterval(interval);
      },[]);

      function loadDB(){
        const fetchEffortLogs = async () => {
          const q = query(collection(db, "EffortLogs"), where("userId", "==", currentUserId), where("taskId", "==", Task.id));
          const querySnapshot = await getDocs(q);
            let EffortLogItems:EffortLogType[]=[];
            querySnapshot.docs.forEach(doc=>{
              let EffortLog:EffortLogType;
              EffortLog=doc.data();
              EffortLog.id = doc.id;
              EffortLog.isDirty = false;
              EffortLogItems.push(EffortLog);
            })
            setEffortLogs(EffortLogItems)
          }
          fetchEffortLogs().catch((e)=>{ErrorHandler(e, "Fetch EffortLogs")});  
    
      }

      async function saveDB(){

        let activeCycle=false;
        EffortLogsRef.current.forEach(async (log,i)=>{
          if(log.isDirty===true)
          {   
              activeCycle=true;
              if(log.id===undefined)
              {
                  await addDoc(collection(db, "EffortLogs"), log ).then((docRef)=>{
                      setEffortLogs(EffortLogsRef.current?.map((log,j)=>{if(i==j){log.isDirty=false; log.id=docRef.id;}return log;})
                  )}).catch((e)=>{ErrorHandler(e, "Add EffortLog");});
              }
              else
              {
                  await setDoc(doc(db, "EffortLogs", log.id??""), log).then(()=>{
                    setEffortLogs(EffortLogsRef.current?.map((log,j)=>{if(i==j){log.isDirty=false;}return log;})
                  )}).catch((e)=>{ErrorHandler(e, "Edit Effort Log");});
              }
          }
      })


      if(activeCycle===false)
      {
          let status="";
  
          if(EffortLogsRef.current.some(log=>log.isDirty===true))
          {
              status="We're sorry .. something went wrong.";
          }
          if(!(setstatusTextRef.current==="We're sorry .. something went wrong.")){setstatusText(status);}
      }

      }

      async function ErrorHandler(error:Error, SourceFunction:string)
      {
        let errorLog:ErrorLogType = {file: "CalendarLog", message: error.message, userId:currentUserId??"", date:new Date(), sourceFunction: SourceFunction, }
        await addDoc(collection(db, "ErrorLogs"), errorLog );
        setstatusText("We're sorry .. something went wrong.");
      }
  


    return (
        <Paper elevation={0} 
        sx={{
            p: 2,
            my: 2,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 5,
            alignContent:'center',
        }}>

          <Title>Effort Logging</Title>

          <Typography color={"darkblue"} sx={{pt:2, height:'30px'}} >{statusText}</Typography>


          <Box sx={{height:300}}>
          <ResponsiveCalendar
              data={[...data, {value: undefined as unknown as number, day: SelectedDate}]}
              //from="2015-03-01"
              from={Task.created!}
              to={Task.deadline!}
              onClick={CalendarClickHandler}
              minValue={0}
              maxValue={24}
              tooltip={({ day, value, color }:any) => (<Typography sx={{fontSize:'10'}}>{day}</Typography>)}

              //to="2015-05-01"
              emptyColor="#eeeeee"
              colors={[ '#61cdbb', '#97e3d5', '#e8c1a0', '#f47560' ]}
              margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
              yearSpacing={40}
              monthBorderColor="#ffffff"
              dayBorderWidth={2}
              dayBorderColor="#ffffff"
              legends={[
                  {
                    anchor: 'bottom-right',
                    direction: 'row',
                    translateY: 36,
                    itemCount: 4,
                    itemWidth: 42,
                    itemHeight: 36,
                    itemsSpacing: 14,
                    itemDirection: 'right-to-left'
                  }
              ]}
          />
          </Box>


          <Typography sx={{fontSize:14,p:1}} color={"primary"} >Total Hours: {EffortLogs.reduce((sum, value)=>{if(value.enabled===true) {sum.hours=(sum.hours??0)+(value.hours??0);} return sum;}, {hours:0}).hours??0}</Typography>
          <Box sx={{ p:2, mx:2, border:1, borderRadius:5, borderColor:"#7196C1", 
          width:'100%', alignSelf:'center', alignItems: 'center', ":hover": {boxShadow: 15} 
          ,display: 'flex',
          flexDirection: 'column',
          }}>


          <Tooltip title={(Task.assignee?.assigneeId!==currentUserId) ? "Only task assignee can log effort.":""} enterTouchDelay={0} leaveTouchDelay={5000} arrow>
            <ToggleButtonGroup
              sx={{pb:2}}
              color="primary"
              value={EffortLogs.find(log=>(log.date===SelectedDate))?.enabled}
              exclusive
              onChange={EnableDisableLog}
              aria-label="Platform"
            >
              <ToggleButton sx={{textTransform: 'none'}} disabled={Task.assignee?.assigneeId!==currentUserId} value={true}>Enable</ToggleButton>
            </ToggleButtonGroup>
          </Tooltip>

            <Box sx={{width:'100%',
            opacity: ((EffortLogs.find((log:EffortLogType)=>(log.date===SelectedDate))?.enabled===true)? 1 : 0.3)}}
            >
              <LocalizationProvider width={'100%'} dateAdapter={DateAdapter}>
                <DesktopDatePicker
                label="Date"
                inputFormat="dd/MM/yyyy"
                value={SelectedDate}
                onChange={(newValue) => {}}
                renderInput={(params:any) => <TextField {...params} />}
                readOnly
                />
              </LocalizationProvider>

              <Tooltip title="Set Hours" enterTouchDelay={0} leaveTouchDelay={5000} arrow>
                <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  alignSelf:'center',
                  justifyContent:'center',
                  gap: 2,
                  p:2,
                }}>
                  <IconButton
                    size="small"
                    onClick={()=>changeHours((EffortLogs.find(log=>(log.date===SelectedDate))?.hours??8)-1)}
                  >
                    <Remove />
                  </IconButton>
                  <Typography >
                    {EffortLogs.find(log=>(log.date===SelectedDate))?.hours}
                  </Typography>
                  <IconButton
                    size="small"
                    onClick={()=>changeHours((EffortLogs.find(log=>(log.date===SelectedDate))?.hours??8)+1)}
                  >
                    <Add />
                  </IconButton>
                </Box>
              </Tooltip>

              <TextareaAutosize
                  aria-label="minimum height"
                  minRows={3}
                  placeholder="Description"
                  value={EffortLogs.find(log=>(log.date===SelectedDate))?.description??""}
                  style={{ width: '100%', margin:"normal"}}
                  onChange={changeDescription}
              />

          </Box>
        </Box>



        </Paper>
    );
}
