import { Box, Paper, Typography } from "@mui/material";
import { ComputedNode, ResponsiveNetwork } from '@nivo/network'
import Title from "./Title";
import { useEffect, useState } from "react";
import { TaskStatusType } from "./Interfaces";
import React from "react";
import { TransitionProps } from "@mui/material/transitions";

export default function StateChart ({State, nextStateList, changeStateHandler}:{State:string, nextStateList:string[], changeStateHandler:(newState:TaskStatusType)=>void}) {

  const [data, setData] = useState<{
    nodes: {
        id: string;
        height: number;
        size: number;
        borderWidth: number;
        color: string;
    }[];
    links: {
        source: string;
        target: string;
        distance: number;
        type?: string;
    }[];}>({
    "nodes": [
        {
            "id": "Unassigned",
            "height": 1,
            "size": 24,
            "borderWidth": 2,
            "color": "grey"
          },
          {
            "id": "Active",
            "height": 1,
            "size": 24,
            "borderWidth": 2,
            "color": "grey"
        },
        {
            "id": "InReview",
            "height": 1,
            "size": 24,
            "borderWidth": 2,
            "color": "grey"
        },
        {
            "id": "Done",
            "height": 1,
            "size": 24,
            "borderWidth": 2,
            "color": "grey"
        },
        {
            "id": "Blocked",
            "height": 1,
            "size": 24,
            "borderWidth": 2,
            "color": "grey"
        },
        {
            "id": "Failed",
            "height": 1,
            "size": 24,
            "borderWidth": 2,
            "color": "grey"
        },
        ],
        "links": [
            {
                "source": "Unassigned",
                "target": "Active",
                "distance": 100,
                "type": "directed"
              },
              {
                "source": "Active",
                "target": "InReview",
                "distance": 100,
                "type": "bidirectional"
              },
              {
                "source": "Active",
                "target": "Blocked",
                "distance": 100,
                "type": "bidirectional"
              },
              {
                "source": "Active",
                "target": "Failed",
                "distance": 100,
                "type": "directed"
              },
              {
                "source": "InReview",
                "target": "Done",
                "distance": 100,
                "type": "directed"
              },
              {
                "source": "Blocked",
                "target": "Failed",
                "distance": 100,
                "type": "directed"
              },
        ] 
  }
  );
  
  useEffect(()=>{
    setData ( {nodes:
    data.nodes.map(node=>{

     if (node.id===State){
       node.color="rgb(244, 117, 96)"
       node.size=40;
       node.borderWidth=10;
       
     }
     else if (nextStateList.includes(node.id)){
      node.color="rgb(244, 117, 96)"
      node.size=24;
      node.borderWidth=2;
      
    }
     else
     {
        node.color="grey"
        node.size=24;
        node.borderWidth=2;
     }
  return node;
  }), links: data.links}
    )
  },[State]);


  return (

      <Paper elevation={0} 
      sx={{
          py: 2,
          my: 2,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 5,
          //height:300,
      }}>

        <Title>Task Status Flow</Title>

    <Box sx={{height:300}}>
    <ResponsiveNetwork
        data={data}
        onClick={(node, event) => {
          if(nextStateList.includes(node.id as TaskStatusType))
          {
            changeStateHandler(node.id as TaskStatusType)
          }
        }}

        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        linkDistance={function(e){return (e.distance*window.innerWidth/1800)}}
        repulsivity={500}
        iterations={200}
        nodeSize={function(n){return n.size}}
        activeNodeSize={function(n){return 1.5*n.size}}
        nodeColor={function(e){return e.color}}
        nodeBorderWidth={function(e){return e.borderWidth}}
        nodeBorderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    0.8
                ]
            ]
        }}
        linkThickness={function(n){return 2+2*n.target.data.height}}
        //linkColor={{ from: 'source.color', modifiers: [] }}
        linkColor={
          function(e)
          {
            
            if(nextStateList.includes(e.target.id) && e.source.id===State)
            {
              return "rgb(244, 117, 96)"
            }
            else if(nextStateList.includes(e.source.id) && e.target.id===State && e.data.type==="bidirectional")
            {
              return "rgb(244, 117, 96)"
            }
            else
            {
              return "grey"
            }
          }
        }
        linkBlendMode="normal"
        motionConfig="wobbly"
        // annotations={[
        //     {
        //         type: 'circle',
        //         match: {
        //             id: State
        //         },
        //         note: State,
        //         noteX: 50,
        //         noteY: 0,
        //         offset: 6,
        //         noteTextOffset: 5
        //     }
        // ]}
    />
    </Box>
    <Typography>{State}</Typography>
    <Typography variant="caption" sx={{textAlign:"center"}}>Click on the node to change the status if you're the current assignee.</Typography>
    </Paper>
    
    );
}