import { Box, Paper, Typography } from '@mui/material';
import { ResponsiveBullet } from '@nivo/bullet'
import Title from './Title';
import { useEffect, useState } from 'react';
import { start } from 'repl';

export default function BulletChart ({startDateString, DeadlineString}:{startDateString:string, DeadlineString:string})
{

    //let startDateString = "Wed Mar 1 2023"
    //let DeadlineString = "Wed May 31 2023"
    //let Deadline = Date.parse("Wed Mar 1 2023")
    //let startDate = Date.parse("Wed May 31 2023")

    const [data, setData] = useState<{
        id: string;
        ranges: number[];
        measures: number[];
        markers: number[];
    }[]>(
        [{
            "id": "",
            "ranges": [
                0 ,
                1
            ],
            "measures": [
              //8
            ],
            "markers": [
                0
            ]
          }]
    )

      useEffect(()=>{
        console.log(startDateString, DeadlineString)
        let Deadline = Date.parse(DeadlineString)
        let startDate = Date.parse(startDateString)    
        setData (
            [{
                "id": "",
                "ranges": [
                    0 ,
                    Math.floor((Deadline-startDate)/86400000)
                ],
                "measures": [
                  //8
                ],
                "markers": [
                    Math.floor((Date.parse(new Date().toUTCString())-startDate)/86400000)
                ]
              }]
        )
      },[startDateString, DeadlineString]);

// let data = [{
//     "id": "",
//     "ranges": [
//         0 ,
//         Math.floor((Deadline-startDate)/86400000)
//     ],
//     "measures": [
//       //8
//     ],
//     "markers": [
//         Math.floor((Date.parse(new Date().toUTCString())-startDate)/86400000)
//     ]
//   }]
    return (
        <Paper elevation={0} 
        sx={{
            py: 2,
            my: 2,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 5,
            height:140,
        }}>
            <Title>
                Task Timeline
            </Title>

            <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between", px:2}}>
                <Typography sx={{fontSize:10}} align="left" >Task Created</Typography>
                <Typography sx={{fontSize:10}} align="right" >Task Deadline</Typography>
            </Box>

            <Box height={50}>
            <ResponsiveBullet
            data={data}
            minValue="auto"
            maxValue="auto"
            
            margin={{ top: 10, right: 50, bottom: 30, left: 50 }}
            //titleAlign="start"
            //titleOffsetX={-70}
            />
            </Box>

            <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between", px:2}}>
                <Typography sx={{fontSize:10}} align="left" >{startDateString??""}</Typography>
                <Typography sx={{fontSize:10}} align="right" >{DeadlineString??""}</Typography>
            </Box>
        </Paper>
    );
}
