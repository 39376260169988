import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import DeleteOutlined from '@mui/icons-material/DeleteOutlined'
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';

export default function ProjectCard({ project, showButtons }) {
  return (
    <div>
      <Card 
        elevation={3} 
        sx={{backgroundColor:'#F0F0F0',
          borderRadius: 5,
          ':hover': {
            boxShadow: 15,
            backgroundColor:'white'
          },}}

      >
        <CardHeader
          title={project.projectTitle}
          subheader= {'Category: ' +  project.category}
        />
        <CardContent>
          <Typography variant="body2" color="textSecondary">
            { project.description }
          </Typography>
          <br />

          {showButtons &&
            <Button sx={{textTransform: 'none'}} fullWidth variant="outlined"component={props => <Link {...props} to={"/Home/Project/"+project.id} />}>View Details</Button>
          }

        </CardContent>
      </Card>
    </div>
  )
}
