import * as React from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { getRowIdFromRowModel } from '@mui/x-data-grid/internals';
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Link } from 'react-router-dom';
import { EquityOrderType, ErrorLogType, ReviewType } from './Interfaces';
import useWindowDimensions from './useWindowDimensions';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../firebase';
import useState from 'react-usestateref';
import { addDoc, collection, doc, getDocs, query, setDoc, where } from 'firebase/firestore';



export default function OrderTable({EquityOrders, statusRef, setStatusFn, setopenBuySharesPanelOrderId, currency}:
  {EquityOrders:EquityOrderType[], statusRef:any, setStatusFn:(status:string)=>void, setopenBuySharesPanelOrderId:(orderId:string)=>void, currency:string}) {

    const [orderType, setorderType] = useState<string>("Buy");
    const [RatingsMaps, setRatingsMaps] = useState<Map<string,number>[]>([]);


    const [user, loading, error] = useAuthState(auth);
    let currentUserId = user?.uid;
    let currentUserName = user?.displayName??"";

    const [EquityOrdersState, setEquityOrdersState, EquityOrdersStateRef] = useState<EquityOrderType[]>([]);

    React.useEffect(
      ()=>{
        setEquityOrdersState(EquityOrders);
  },[EquityOrders]);


  function AverageRating(Reviews:ReviewType[])
  {
    return Reviews.reduce((sum:number, value)=>{sum =sum+(value.Rating??0); return sum;}, 0)/(Reviews.length!==0 ? Reviews.length:1)*100/5;
  }

  React.useEffect(
    ()=>{
        //loadDB();
        const interval = setInterval(() => {
            saveDBEdit();
        },2*1000);
        return () => clearInterval(interval);
    },[]);

    function getOwnersRating(ownerId:string){

      console.log(RatingsMaps);
      console.log(ownerId)

        const fetchReviews = async (ownerId:string) => {
          console.log("fetching reviews");
          console.log(ownerId);
          const q = query(collection(db, "Reviews"), where("targetId", "==", ownerId));
          const querySnapshot = await getDocs(q);
              let Reviews:ReviewType[]=[];
              querySnapshot.docs.forEach(doc=>{
              let Review:ReviewType;
              Review=doc.data() as ReviewType;
              Review.id = doc.id;
              Review.isDirty = false;
              Reviews.push(Review);
              })
              console.log(Reviews);
              setRatingsMaps([...RatingsMaps, new Map([[ownerId, AverageRating(Reviews)]])] );
              console.log(RatingsMaps);
              console.log(AverageRating(Reviews));
              return AverageRating(Reviews);
          }

          if(RatingsMaps?.find(map=>map.has(ownerId))===undefined )
          {
            console.log("not found in map");
            fetchReviews(ownerId).catch((e)=>{ErrorHandler(e, "Fetch Reviews")}).then((rating)=>{return rating});  
          }
          else
          {
            console.log("found in map");
            console.log(RatingsMaps?.find(map=>map.has(ownerId))?.get(ownerId));
            return RatingsMaps?.find(map=>map.has(ownerId))?.get(ownerId);
          }
     }
    



    async function saveDBEdit(){
      let activeCycle=false;

      console.log(EquityOrdersStateRef);

      EquityOrdersStateRef.current.forEach(async (order,i)=>{
          if(order.isDirty===true)
          {   
              activeCycle=true;
              if(order.status==="Deleted")
              {
                  await setDoc(doc(db, "EquityOrders", order.id??""), order).then(()=>{
                    setEquityOrdersState(EquityOrdersStateRef.current?.map((order,j)=>{if(i==j){order.isDirty=false; }return order;})
                  )}).catch((e)=>{ErrorHandler(e, "Edit EquityOrder");});
              }
          }
      })

      if(activeCycle===false)
      {
          let status="";

          if(EquityOrdersStateRef?.current.some(order=>order.isDirty===true))
          {
              status = "We're sorry .. something went wrong.";
              
          }
          if(!(statusRef.current==="We're sorry .. something went wrong.")){setStatusFn(status);}
      }
      
  }
  
  async function ErrorHandler(error:Error, SourceFunction:string)
  {
    let errorLog:ErrorLogType = {file: "OrderTable", message: error.message, userId:currentUserId??"", date:new Date(), sourceFunction: SourceFunction, }
    await addDoc(collection(db, "ErrorLogs"), errorLog );
    setStatusFn("We're sorry .. something went wrong.");    
  }




    return (
      <Box sx={{display:'flex', flexDirection:'column', alignItems:'center'}}>

        <ToggleButtonGroup
        sx={{m:1}}
        value={orderType}
        onChange={(event, value)=>{
            if(value!==null)
            {setorderType(value)}
        }}
        aria-label="text formatting"
        exclusive
        orientation= {(useWindowDimensions().width<800)? "vertical":"horizontal"}
        >
            <ToggleButton sx={{fontSize:'min(2.5vw,10px)'}} value="Buy">Buy</ToggleButton>
            <ToggleButton sx={{fontSize:'min(2.5vw,10px)'}} value="Sell" >Sell</ToggleButton>
        </ToggleButtonGroup>


        <TableContainer elevation={5} component={Paper} sx={{p:2}}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>Owner</TableCell>
                <TableCell style={{ fontWeight: 'bold' }} align="left">Owner Rating</TableCell>
                <TableCell style={{ fontWeight: 'bold' }} align="left">Price ({currency})</TableCell>
                <TableCell style={{ fontWeight: 'bold' }} align="left">Quantity</TableCell>
                <TableCell style={{ fontWeight: 'bold' }} align="left">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>

                {/* If user wants to buy, present him orders of type sell */}
              {EquityOrdersState?.filter(order=>order.type!==orderType).filter(order=>order.status==="Pending").sort((a, b)=> {
                if(orderType==="Buy")
                {
                  return ((a.price??0) - (b.price??0));
                }
                else 
                {
                  return ((b.price??0) - (a.price??0));
                }
              }).map((order) => (
              <TableRow key={order.id}>
                <TableCell >{order?.username}</TableCell>
                <TableCell >{getOwnersRating(order?.userId??"")}%</TableCell>
                <TableCell>{order?.price}</TableCell>
                <TableCell>{order?.shares}</TableCell>
                <TableCell>

                {order.userId===currentUserId&&
                <Button sx={{textTransform: 'none', fontSize:12}} variant="contained" 
                onClick={()=>{
                  setEquityOrdersState(EquityOrdersState.map((orderItem)=>{
                    if(orderItem.id===order.id)
                    {
                      orderItem.status="Deleted";
                      orderItem.isDirty=true;
                      setStatusFn("Saving..");
                    }
                    return orderItem;
                  }))
                  
                }}>Delete</Button>
                }
                {order.userId!==currentUserId&&order.type==="Buy"&&
                <Button sx={{textTransform: 'none', fontSize:12}} variant="contained" onClick={() =>{}}>Sell</Button>
                }
                {order.userId!==currentUserId&&order.type==="Sell"&&
                <Button sx={{textTransform: 'none', fontSize:12}} variant="contained" onClick={() =>{setopenBuySharesPanelOrderId(order.id??"")}}>Buy</Button>
                }


                </TableCell>

              </TableRow>
              ))}

            </TableBody>
          </Table>
        </TableContainer>
        </Box>
      );
    }