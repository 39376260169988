import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import Home from './Home/Home';
import { BrowserRouter, BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { CompatRoute, CompatRouter } from "react-router-dom-v5-compat";
import LandingPage from './LandingPage';
import Projects from './Home/Projects';
import CreateOrEditProject from './Home/CreateOrEditProjectOld';
import Project from './Home/Project';
import CreateOrEditTask from './Home/CreateOrEditTask';
import User from './Home/User';
import Tasks from './Home/Tasks';
import Grid from '@mui/material/Grid';
import Dashboard from './Home/Dashboard';

function App() {
 
  return (
    <div className="App">
      <header className="App-header">
        <Router>
          <CompatRouter>
            <CompatRoute exact path="/" component={LandingPage} />
            <CompatRoute path="/Home" component={Home} />
          </CompatRouter>
        </Router>
      </header>
    </div>
  );
}

export default App;
