import { Box, Typography } from "@mui/material";
import Logoimage from '../Image/logo4.png'; 

export default function LogoCompReduced()
{
    return (
        <Box sx={{ml:2, display:"flex", flexDirection:'row'}}>
        <Box
              component="img"
              sx={{
                height: '45px',
                width: '60px',
                mt:2, mr:1
              }}
              alt=""
              src={Logoimage}
              />
        <Box sx={{display:"flex", flexDirection:'column'}}>
        <Typography fontFamily={'revert'} fontSize={40} fontWeight={'bolder'} color={'rgb(37, 150, 190)'} 
        sx={{textShadow:'1px 1px 1px', WebkitTextStroke:'0.5px black'}}>EffortFund</Typography>
        <Typography fontFamily={'fantasy'} fontSize={11.5} color={'black'} fontWeight={'normal'} sx={{mt:-1}}>A Partnership Management Platform</Typography>
        </Box>
      </Box>
    );
}

