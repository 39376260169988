
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAuth, 
        createUserWithEmailAndPassword, 
        signInWithEmailAndPassword, 
        onAuthStateChanged, 
        GoogleAuthProvider, 
        signInWithPopup, 
        sendPasswordResetEmail, 
        signOut, 
        updateProfile,
        signInAnonymously,
        EmailAuthProvider,
        linkWithCredential,
        User,
        linkWithPopup} 
  from "firebase/auth";

import {getFirestore, 
        query, 
        getDocs, 
        collection, 
        where, 
        addDoc,
        setDoc,
        doc}
  from "firebase/firestore";

import {UserType} from './Home/Interfaces';
import { createGlobalState } from 'react-hooks-global-state';
import { useHistory } from "react-router-dom";

const currentUser:UserType={
    uid: "",
    name: ""
  };

//const {useGlobalState, setGlobalState } = createGlobalState(currentUser);
    
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCs25FytbBDDu__88JnYS1p4W3gCWHSzXA",
  authDomain: "effortfund.firebaseapp.com",
  databaseURL: "https://effortfund.firebaseio.com",
  projectId: "effortfund",
  storageBucket: "effortfund.appspot.com",
  messagingSenderId: "377646846901",
  appId: "1:377646846901:web:6bb92e616fc8ba8a34fffc",
  measurementId: "G-BVBMMJY6WH",

};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);


  const signInWithGoogle = async (context:("Default"|"Guest")) => {
  try {
      let user:User;
      const googleProvider = new GoogleAuthProvider();

      if(context=="Guest")
      {
        const auth = getAuth();
        await linkWithPopup(auth.currentUser!, googleProvider).then((result) => {
          // Accounts successfully linked.
          const credential = GoogleAuthProvider.credentialFromResult(result);
          user = result.user;
          // ...
          return user;
        })
        .then((user)=>{updateProfile(user, {displayName:(user.email!.split('@')[0])}); return user;})
        .then(async (user)=>{
          const q = query(collection(db, "Users"), where("uid", "==", user.uid));
          const docs = await getDocs(q);    
          return docs;
        })
        .then(async (docs) =>{
          if (docs.docs.length === 0) 
            {
              await setDoc(doc(db, "Users", user.uid), {
                  uid: user.uid,
                  name: user.email!.split('@')[0],
                  authProvider: "google",
                  email: user.email,
              });
            }      
        });
        

      }
      else //(context=="Default")
      {
        await signInWithPopup(auth, googleProvider).then((usercred) => {
          user = usercred.user;
        }).then(()=>{updateProfile(user, {displayName:user.displayName})})

        .then(async ()=>{
          const q = query(collection(db, "Users"), where("uid", "==", user.uid));
          const docs = await getDocs(q);    
          return docs;
        })
        .then(async (docs) =>{
          if (docs.docs.length === 0) 
            {
              await setDoc(doc(db, "Users", user.uid), {
                  uid: user.uid,
                  name: user.displayName,
                  authProvider: "google",
                  email: user.email,
              });
            }      
        })
      }
      

  } catch (err:any) {
      alert(err.message);
  }
  };



  const logInWithEmailAndPassword = async (email:string, password:string) => {
      const res = await signInWithEmailAndPassword(auth, email, password);
      const user = res.user;
      return res;
  };
  

  const registerWithEmailAndPassword = async (name:string, email:string, password:string, context:("Default"|"Guest")) => {
    
    let user:User;
    if(context == "Default")
    {
      const res = await createUserWithEmailAndPassword(auth, email, password);
      user = res.user;
      await updateProfile(user, {displayName:name}).then(()=>{
        setDoc(doc(db, "Users", user.uid), {
          uid: user.uid,
          name,
          authProvider: "local",
          email,
        });  
      })
    }
    else //(context == "Guest")
    {
      const credential = EmailAuthProvider.credential(email, password);
      const auth = getAuth();

      await linkWithCredential(auth.currentUser!, credential)
        .then((usercred) => {
          user = usercred.user;
        })
        .then(()=>{updateProfile(user, {displayName:name})})
        .then(()=>{
          console.log(user)
          setDoc(doc(db, "Users", user.uid), {
            uid: user.uid,
            name,
            authProvider: "local",
            email,
          });  
        })
    }
  };


  const sendPasswordReset = async (email:string) => {
    try {
      await sendPasswordResetEmail(auth, email);
      alert("Password reset link sent!");
    } catch (err:any) {
      alert(err.message);
    }
  };

  
  const logout = () => {
    signOut(auth);
  };

  async function IsUser (email:string)
  {
    const q = query(collection(db, "Users"), where("email", "==", email));
    const docs = await getDocs(q);
    
    if (docs.docs.length === 0) 
    {
      return false;
    }
    else
    {
      return true;
    }
  };

  async function GuestSignIn ()
  {
    const auth = getAuth();
    signInAnonymously(auth)
      .then(() => {
        // Signed in..
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
      });
};


  export {
    auth,
    db,
    storage,
    signInWithGoogle,
    logInWithEmailAndPassword,
    registerWithEmailAndPassword,
    sendPasswordReset,
    logout,
    IsUser,
    GuestSignIn
  };
  
  