import { Box, Button, Paper, Slider, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material'
import { DefaultLink, DefaultNode, ResponsiveSankey, SankeyMouseHandler } from '@nivo/sankey'
import { EquityComponentType, ProjectType, TransactionType, projectMembershipType } from './Interfaces';
import { useEffect } from 'react';
import useState from 'react-usestateref';


export default function InitEquityAssignmentChart({equityComps, projectMemberships, project}:
    {equityComps:EquityComponentType[], projectMemberships:projectMembershipType[], project:ProjectType}){

        

    
    const [Transactions, setTransactions, TransactionsRef] = useState<TransactionType[]>([]);
    const [SelectedTransaction, setSelectedTransaction, SelectedTransactionRef] = useState<TransactionType>();

    useEffect(
        ()=>{

            ResetTransactions();

        },[project, equityComps, projectMemberships]);


        useEffect(
            ()=>{

                console.log(Transactions)
                //setSelectedTransaction(Transactions[0]);
            },[Transactions]);
    
            function ResetTransactions()
            {
                let transactionsBuffer: TransactionType[] = [];

                equityComps.forEach(comp => {
                    if(comp.type == 'outstanding' || comp.type == 'cash' )
                    {
                        let totalSharesPerComp = comp.targetEquityShares??0;
                        let sharesPerMemberInComp = Math.ceil((comp.targetEquityShares??0)/(projectMemberships.length));
                        console.log(sharesPerMemberInComp)
        
                        projectMemberships.forEach(membership => {
        
                            let assignedShares = 0;
        
                            if(totalSharesPerComp > sharesPerMemberInComp)
                            {
                                assignedShares = sharesPerMemberInComp;
                                totalSharesPerComp -= sharesPerMemberInComp;
                            }
                            else
                            {
                                assignedShares = totalSharesPerComp;
                            }
        
                            let transaction:TransactionType;
    
                            if(comp.type == 'outstanding')
                            {
                                transaction = 
                                {
                                    date:new Date(), 
                                    destinationId: membership.userId,
                                    destinationType:'user',
                                    destinationName: membership.memberName,
                                    sourceId: project.id,
                                    sourceType:'project',
                                    sourceName: project.projectTitle,
                                    equityComponent: comp.name,
                                    status:'Confirmed',
                                    type:'equity',
                                    value:assignedShares,
                                    locked:false
                                }
                                transactionsBuffer.push(transaction);
    
                            }
                             else 
                            {
                                transaction = 
                                {
                                    date:new Date(), 
                                    destinationId: membership.userId,
                                    destinationType:'user',
                                    destinationName: membership.memberName,
                                    sourceId: project.id,
                                    sourceType:'project',
                                    sourceName: project.projectTitle,
                                    equityComponent: comp.name,
                                    status:'Confirmed',
                                    type:'equity',
                                    value:assignedShares,
                                    locked:false
                                }
                                transactionsBuffer.push(transaction);
    
                                transaction = 
                                {
                                    date:new Date(), 
                                    sourceId: membership.userId,
                                    sourceType:'user',
                                    sourceName: membership.memberName,
                                    destinationId: project.id,
                                    destinationName: project.projectTitle,
                                    destinationType:'project',
                                    equityComponent: comp.name,
                                    status:'Confirmed',
                                    type:'cash',
                                    value:assignedShares,
                                    locked:false
                                }
                                transactionsBuffer.push(transaction);
    
                            }
                        })
        
                        setTransactions(transactionsBuffer);
                    }
                })
            }

    return(

        
        <Paper elevation={0} 
        
        sx={{
            py: 2,
            my: 2,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 5,
            width: '100%',

            //alignItems:"center"
        }}>

            

            <Typography variant="caption" sx={{textAlign:"center"}}>Click on an assignment link to change its allocated shares.</Typography>


            {SelectedTransaction&&
            <Typography variant="caption" sx={{fontSize:'min(3vw,15px)', mx:'5vw', my:3, opacity:0.8, width:'40%', alignSelf:'center'}}>
                Out of <strong>{equityComps.find(comp=>(comp.name == SelectedTransaction?.equityComponent))?.targetEquityShares}</strong> Shares 
            assigned to the <strong>{SelectedTransaction?.equityComponent}</strong> equity component, 
            <TextField inputProps={{ style: { textAlign: 'center' }}} size="small" value={SelectedTransaction?.value} id="standard-basic" label="Shares" variant="standard" type="number"
                sx={{position:"inherit", width:'80px', mx:0.5, mt:-2.5}} onChange={(setValue)=>
                    {
                        setTransactions(Transactions.map(trans=>
                            {
                                
                                if((trans.equityComponent == SelectedTransaction?.equityComponent) && (trans.destinationId == SelectedTransaction?.destinationId))
                                    {
                                        let maxValue = (equityComps.find(comp=>(comp.name == SelectedTransaction?.equityComponent))?.targetEquityShares??0)
                                        - (Transactions.filter(trans=>(trans.equityComponent == SelectedTransaction?.equityComponent)).reduce((sum:number, value)=>{sum =sum+(value.value??0); return sum;}, 0))
                                        + (trans.value??0);

                                        if(parseInt(setValue.target.value) <= (maxValue))
                                        {
                                            trans.value = parseInt(setValue.target.value);
                                        }
                                        else
                                        {
                                            trans.value = maxValue;
                                        }
                                    }
                                return trans;
                            }
                        ));
                    }} /> Shares are allocated 
                to <strong>{SelectedTransaction?.destinationName}</strong>, this represnets <strong>{Math.round((SelectedTransaction?.value??0)/(equityComps.find(comp=>(comp.name == SelectedTransaction?.equityComponent))?.targetEquityShares??1)*10000)/100}</strong>% of
                the equity component's shares.

            </Typography>
            }
            
                
            
            <Box width='25%' alignSelf={"center"} sx={{display:"flex", flexDirection:'row', mt:2, justifyContent:'space-evenly'}}>

            <Button sx={{textTransform: 'none', color:'black', backgroundColor:'#DCDCDC'}} variant="contained" onClick={()=>ResetTransactions()}>Reset</Button>

        </Box>

        <Box sx={{height:600, width:'100%'}} >

            <ResponsiveSankey
                data={{
                    "nodes": 
                    
                    (equityComps.filter(comp=>(comp.type=='outstanding'||comp.type=='cash'))
                    .filter(comp=>((comp.targetEquityShares??0) > (Transactions.filter(trans=>(trans.equityComponent == comp.name))
                    .reduce((sum:number, value)=>{sum =sum+(value.value??0); return sum;}, 0)))).length == 0)
                    ?
                    (equityComps.filter(comp=>(comp.type=='outstanding'||comp.type=='cash')).map((comp) => ({"id": comp.name} as DefaultNode)).concat(
                    projectMemberships.map((member) => ({"id": member.memberName} as DefaultNode)))) 
                    : 
                    (equityComps.filter(comp=>(comp.type=='outstanding'||comp.type=='cash')).map((comp) => ({"id": comp.name} as DefaultNode)).concat(
                        projectMemberships.map((member) => ({"id": member.memberName} as DefaultNode))).concat(({"id": "Unallocated"} as DefaultNode)) ) 
                    ,

                    
                    "links": Transactions.filter(transaction=> (transaction.type=="equity")).map(transaction => 
                        ({"source": transaction.destinationName, "target": transaction.equityComponent, "value":(transaction.value), 
                            "startColor":((transaction.equityComponent == SelectedTransaction?.equityComponent) && (transaction.destinationId == SelectedTransaction?.destinationId)) ? null: 'darkgrey',
                            "endColor":((transaction.equityComponent == SelectedTransaction?.equityComponent) && (transaction.destinationId == SelectedTransaction?.destinationId)) ? null: 'darkgrey',
                        } as DefaultLink))
                        .concat(equityComps
                        .filter(comp=>(comp.type=='outstanding'||comp.type=='cash'))
                        .filter(comp=>((comp.targetEquityShares??0) > (Transactions.filter(trans=>(trans.equityComponent == comp.name)).reduce((sum:number, value)=>{sum =sum+(value.value??0); return sum;}, 0))))
                        .map(comp=>{
                                    console.log(comp.name);
                                    console.log(comp.targetEquityShares);
                                    console.log(Transactions.filter(trans=>(trans.equityComponent == comp.name)).reduce((sum:number, value)=>{sum =sum+(value.value??0); return sum;}, 0));        
                                    return({"source": "Unallocated", "target": comp.name, 
                                        "value":((comp.targetEquityShares??0) - (Transactions.filter(trans=>(trans.equityComponent == comp.name)).reduce((sum:number, value)=>{sum =sum+(value.value??0); return sum;}, 0))), 
                                        "startColor":'darkgrey',
                                        "endColor":'darkgrey',} as DefaultLink)    
                    }))
                }}

                onClick={(node:any) => 
                    {
                        setSelectedTransaction(Transactions.find(trans=>((trans.destinationName == node.source.label) && (trans.equityComponent == node.target.label))));
                    }
                }
                
                margin={{ top: 40, right: 50, bottom: 40, left: 50 }}
                align="justify"
                colors={{ scheme: 'category10' }}
                nodeOpacity={1}
                nodeHoverOthersOpacity={0.35}
                nodeThickness={18}
                nodeSpacing={24}
                nodeBorderWidth={0}
                nodeBorderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            0.8
                        ]
                    ]
                }}
                nodeBorderRadius={3}
                linkOpacity={0.5}
                linkHoverOthersOpacity={0.1}
                linkContract={3}
                enableLinkGradient={true}
                labelPosition="outside"
                labelOrientation="vertical"
                labelPadding={16}
                labelTextColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1
                        ]
                    ]
                }}
            />
        </Box>
    </Paper>
    );
}