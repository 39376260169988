import { Box, Container, Paper, Rating, TextareaAutosize, Typography } from "@mui/material";
import Title from "./Title";
import { ResponsiveBar } from '@nivo/bar'
import React, { useEffect } from "react";
import useState from 'react-usestateref';
import StarIcon from '@mui/icons-material/Star';
import { ErrorLogType, ReviewType, TaskType } from "./Interfaces";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase";
import { addDoc, collection, doc, getDocs, query, setDoc, where } from "firebase/firestore";
import { set } from "date-fns";
import CompensationChart from "./CompensationChart";

  

  const labels: { [index: string]: string } = {
    1: 'Invalid',
    2: 'Weak',
    3: 'Acceptable',
    4: 'Good',
    5: 'Excellent',
  };

  function getLabelText(value: number) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
  }

export default function ReviewPanel({TargetId}:{TargetId:string}){

    const [hover, setHover] = React.useState(-1);

    const [user, loading, error] = useAuthState(auth);
    let currentUserId = user?.uid;
    let currentUserName = user?.displayName??"";
    const [statusText, setstatusText, statusTextRef] = useState<string>("");
    const [Reviews, setReviews, ReviewsRef] = useState<ReviewType[]>([]);
    const [data, setData] = useState([
        {
          "Reviews": "Ratings",
          "Excellent": 0,
          "ExcellentColor": "hsl(63, 70%, 50%)",
          "Good": 0,
          "GoodColor": "hsl(350, 70%, 50%)",
          "Acceptable": 0,
          "AcceptableColor": "hsl(197, 70%, 50%)",
          "Weak": 0,
          "WeakColor": "hsl(19, 70%, 50%)",
          "Invalid": 0,
          "InvalidColor": "hsl(331, 70%, 50%)",
        }
    ]);


        function loadDB(){

            const fetchReviews = async () => {
                const q = query(collection(db, "Reviews"), where("targetId", "==", TargetId));
                const querySnapshot = await getDocs(q);
                    let Reviews:ReviewType[]=[];
                    querySnapshot.docs.forEach(doc=>{
                    let Review:ReviewType;
                    Review=doc.data() as ReviewType;
                    Review.id = doc.id;
                    Review.isDirty = false;
                    Reviews.push(Review);
                    })
                    setReviews(Reviews);
                }
                fetchReviews().catch((e)=>{ErrorHandler(e, "Fetch Reviews")});  
    
        }
            
        async function saveDBEdit(){
            let activeCycle=false;
            console.log(ReviewsRef.current);

            ReviewsRef.current.forEach(async (review,i)=>{
                if(review.isDirty===true)
                {   
                    activeCycle=true;
                    if(review.id===undefined)
                    {
                        await addDoc(collection(db, "Reviews"), review ).then((docRef)=>{
                            setReviews(ReviewsRef.current?.map((review,j)=>{if(i==j){review.isDirty=false; review.id=docRef.id}return review;})
                        )}).catch((e)=>{ErrorHandler(e, "Add Review");});
                    }
                    else
                    {
                        await setDoc(doc(db, "Reviews", review.id??""), review).then(()=>{
                            setReviews(ReviewsRef.current?.map((review,j)=>{if(i==j){review.isDirty=false; }return review;})
                        )}).catch((e)=>{ErrorHandler(e, "Edit Review");});
                    }
                }
            })
    
            if(activeCycle===false)
            {
                let status="";
    
                if(ReviewsRef?.current.some(review=>review.isDirty===true))
                {
                    status = "We're sorry .. something went wrong.";
                }
    
                if(!(statusTextRef.current==="We're sorry .. something went wrong.")){setstatusText(status);}
            }
            
        }
    

        async function ErrorHandler(error:Error, SourceFunction:string)
        {
          let errorLog:ErrorLogType = {file: "Reviews", message: error.message, userId:currentUserId??"", date:new Date(), sourceFunction: SourceFunction, }
          await addDoc(collection(db, "ErrorLogs"), errorLog );
          setstatusText("We're sorry .. something went wrong.");    
        }
    

        function UpdateReview(rating:number, reviewText:string)
        {
            if(Reviews.find(review=>review.authorId===currentUserId))
            {
                setReviews(Reviews.map(review=>{if(review.authorId===currentUserId){review.Rating=rating; review.ReviewText=reviewText; review.isDirty=true} return review;}))
                setstatusText("Saving..");  
            }
            else
            {
                setReviews([...Reviews, {authorId: currentUserId??"", targetId: TargetId, Rating: rating, ReviewText: reviewText, isDirty:true}]);
                setstatusText("Saving..");  
            }
        }

        
    
        useEffect(
            ()=>{
                loadDB();
                const interval = setInterval(() => {
                    saveDBEdit();
                },2*1000);
                return () => clearInterval(interval);
            },[]);

        useEffect(
            ()=>{
                setData(
                    [
                        {
                          "Reviews": "Ratings",
                          "Excellent": Reviews.filter(review=> (review.Rating===5)).length,
                          "ExcellentColor": "hsl(63, 70%, 50%)",
                          "Good": Reviews.filter(review=> (review.Rating===4)).length,
                          "GoodColor": "hsl(350, 70%, 50%)",
                          "Acceptable": Reviews.filter(review=> (review.Rating===3)).length,
                          "AcceptableColor": "hsl(197, 70%, 50%)",
                          "Weak": Reviews.filter(review=> (review.Rating===2)).length,
                          "WeakColor": "hsl(19, 70%, 50%)",
                          "Invalid": Reviews.filter(review=> (review.Rating===1)).length,
                          "InvalidColor": "hsl(331, 70%, 50%)",
                        }
                    ]  
                )
            },[Reviews]);
    
  
        return(
            <Paper elevation={0} 
                    sx={{
                    p: 2,
                    my:2,
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: 5
                    }}
            >
                <Title>Reviews</Title>
                <Typography color={"darkblue"} sx={{fontSize:10, height:'10px'}} >{statusText}</Typography>

                <Box sx={{height:200}}>
                    <ResponsiveBar
                        data={data}
                        keys={[
                            'Excellent',
                            'Good',
                            'Acceptable',
                            'Weak',
                            'Invalid'
                        ]}
                        indexBy="Reviews"
                        margin={{ top: 60, right: 130, bottom: 60, left: 60 }}
                        padding={0.3}
                        layout="horizontal"
                        valueScale={{ type: 'point' }}
                        indexScale={{ type: 'band', round: true }}
                        colors={{ scheme: 'nivo' }}
                        defs={[
                            {
                                id: 'dots',
                                type: 'patternDots',
                                background: 'inherit',
                                color: '#38bcb2',
                                size: 4,
                                padding: 1,
                                stagger: true
                            },
                            {
                                id: 'lines',
                                type: 'patternLines',
                                background: 'inherit',
                                color: '#eed312',
                                rotation: -45,
                                lineWidth: 6,
                                spacing: 10
                            }
                        ]}
                        borderColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    1.6
                                ]
                            ]
                        }}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            //legend: 'Reviews',
                            legendPosition: 'middle',
                            legendOffset: 32
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            //legend: 'food',
                            legendPosition: 'middle',
                            legendOffset: -40
                        }}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        labelTextColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    1.6
                                ]
                            ]
                        }}
                        legends={[
                            {
                                dataFrom: 'keys',
                                anchor: 'bottom-right',
                                direction: 'column',
                                justify: false,
                                translateX: 120,
                                translateY: 0,
                                itemsSpacing: 2,
                                itemWidth: 100,
                                itemHeight: 20,
                                itemDirection: 'left-to-right',
                                itemOpacity: 0.85,
                                symbolSize: 20,
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemOpacity: 1
                                        }
                                    }
                                ]
                            }
                        ]}
                        role="application"
                        ariaLabel="Nivo bar chart demo"
                        barAriaLabel={e=>e.id+": "+e.formattedValue+" in country: "+e.indexValue}
                    />
                </Box>

                <Typography sx={{fontSize:18, color:'blue', p:2}}>Average rating: {Reviews.reduce((sum:number, value)=>{sum =sum+(value.Rating??0); return sum;}, 0)/(Reviews.length!==0 ? Reviews.length:1)*100/5}%</Typography>

                <Box sx={{ p:2, mx:2, border:2, borderRadius:5, borderColor:"#7196C1", 
                    width:'100%', alignSelf:'center', alignItems: 'center', ":hover": {boxShadow: 15} 
                    ,display: 'flex',
                    flexDirection: 'column',
                    }}>
                        <Typography  sx={{fontSize:16, color:'black', pb:2}} >Your Review</Typography>

                        <Rating name="set-rating" 
                        sx={{}}
                        precision={1} 
                        getLabelText={getLabelText}
                        size="large"
                        onChangeActive={(event, newHover) => {
                            setHover(newHover);
                        }}
                        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                
                        value={Reviews.find(review=>review.authorId===currentUserId)?.Rating??0}
                        onChange={(e:any)=>UpdateReview(parseInt(e.target.value), Reviews.find(review=>review.authorId===currentUserId)?.ReviewText??"")}  
                        />

                        {Reviews.find(review=>review.authorId===currentUserId)?.Rating !== null && (
                            <Typography sx={{fontSize:14, color:'blue', p:1, height:'30px'}}>{labels[hover !== -1 ? hover : Reviews.find(review=>review.authorId===currentUserId)?.Rating??0]}</Typography>
                        )}

                    <TextareaAutosize
                        aria-label="minimum height"
                        minRows={3}
                        placeholder="Write a review .."
                        value={Reviews.find(review=>review.authorId===currentUserId)?.ReviewText??""}
                        style={{ width: '100%', margin:"normal"}}
                        onChange={(e)=>UpdateReview(Reviews.find(review=>review.authorId===currentUserId)?.Rating??0, e.target.value)}
                    />

                </Box>



                <Box sx={{ p:2, m:2, border:2, borderRadius:5, borderColor:"#7196C1", 
                    width:'100%', alignSelf:'center', alignItems: 'center', ":hover": {boxShadow: 15} 
                    ,display: 'flex',
                    flexDirection: 'column',
                    }}>
                        <Typography  sx={{fontSize:16, color:'black'}} >Other Reviews</Typography>

                        {Reviews.filter(review=>review.authorId!==currentUserId).map(review=>(
                        <Box sx={{ p:2, m:2, border:2, borderRadius:5, borderColor:"#7196C1", 
                        width:'100%', alignSelf:'center', alignItems: 'center', ":hover": {boxShadow: 15} 
                        ,display: 'flex',
                        flexDirection: 'column'}}>

                            <Rating name="set-rating" 
                            readOnly
                            precision={1} 
                            size="large"
                            emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                            value={review.Rating??0}
                            />
                            <Typography sx={{fontSize:14, color:'blue'}}>{labels[review.Rating??0]}</Typography>

                            <TextareaAutosize
                            aria-label="minimum height"
                            readOnly
                            minRows={3}
                            placeholder=""
                            value={review.ReviewText??""}
                            style={{ width: '100%', margin:"normal"}}
                            />

                        </Box>))}


                </Box>



            </Paper>               
    );
}