import React, { useEffect, useRef } from 'react'
import { Link, useHistory } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { Box, Button, Collapse, Container, createTheme, Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, MobileStepper, Paper, responsiveFontSizes, TextField, ThemeProvider, Typography, useTheme } from '@mui/material';
import { blue, grey } from '@mui/material/colors';
import EffortFundLogo from './Home/EffortFundLogo';
import { query } from 'firebase/firestore';
import { KeyboardArrowLeft, KeyboardArrowRight, Password } from '@mui/icons-material';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import useState from 'react-usestateref';
import Logo from './Image/logo4.png'; 
import SS1 from './Image/ScreenShot1.jpg'; 
import SS2 from './Image/ScreenShot2.jpg'; 
import SS3 from './Image/ScreenShot3.jpg'; 
import SS4 from './Image/ScreenShot4.jpg'; 
import LogoComp from './Home/LogoComp';
import { auth } from './firebase';
import RegisterPanel from './Home/RegisterPanel';
import LandingPageDemo from './Home/LandingPageDemo';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';

function LandingPage() {
    const [user, loading, error] = useAuthState(auth);

    const history = useHistory();
    const [activeStep, setActiveStep, activeStepRef] = useState(0);

    const missionStatements = ["Start working on your idea with minimal initial funding", 
                               "Manitain a fair and dynamic equity distribution structure", 
                               "Create an environment of friendly competition", 
                               "Reduce the risk of co-founder conflict"]

    const STEP_COUNT = 4;

    const [scrollPosition, setScrollPosition, scrollPositionRef] = useState(0);
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
  };

  function timeout(delay: number) {
    return new Promise( res => setTimeout(res, delay) );
}

    useEffect(() => {
      if (loading) {
        // maybe trigger a loading screen
        return;
      }
      if (user)
      {
        history.push("/Home");
      } 
    }, [user, loading]);

    useEffect(
      ()=>{
          const interval = setInterval(() => {
              PeriodicFunction();
          },3*1000);

          window.addEventListener('scroll', handleScroll, { passive: true });

          return () => {
            clearInterval(interval);
            window.removeEventListener('scroll', handleScroll);
          }
      },[]);


      function PeriodicFunction()
      {
        setActiveStep((activeStepRef.current+1)%STEP_COUNT)
      }


    return(
      <Box 
       sx={{
           width: '100%',
           height: '100%',
           display: 'flex',
           flexDirection: 'column',
           backgroundColor: "white",
       }}
       >

        <Box
        sx={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
          <LogoComp/>

          <Box sx={{display:'flex', flexDirection:'column', alignSelf:'center', mr:"10vw", mt:'3vh', justifyContent:'space-between' }}>
          <Button variant='outlined' size='large' sx={{textTransform:'none', width:'8vw', height:'6vh', alignSelf:'center'}} onClick={
            async ()=>{
              let iter;
              for(iter=0;iter<60;iter++)
              {
                window.scrollTo({ top: (50*iter), behavior: "smooth"});
                await timeout(1); 
              }
            }}>Start</Button>
          
          <Box sx={{display:'flex', flexDirection:'row', mt:'3vh', justifyContent:'space-between'}}>
            <IconButton size='small'>
              <LinkedInIcon/>
            </IconButton>

            <IconButton size='small'>
              <XIcon/>
            </IconButton>
          </Box>
          </Box>
        </Box> 


        <Box sx={{ mx:5,  alignSelf:'center',
        //backgroundImage:`linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url(${require('./Image/ScreenShot'.concat((activeStep+1).toString()).concat(".jpg"))})`,
        backgroundImage:`url(${require('./Image/ScreenShot'.concat((activeStep+1).toString()).concat(".jpg"))})`,
        backgroundPosition: 'bottom',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPositionY:(scrollPosition/4),
        borderRadius:3,
        width:'98vw', 
        height:'50vw',
        display:'flex',
        flexDirection:'column',
        justifyContent:'flex-end'
        }}>
          <Typography color={'white'} align={'left'} fontSize={'min(8vw,90px)'} fontFamily={'fantasy'} 
          sx={{mx:2, textShadow:'1px 1px 1px', WebkitTextStroke:'2px gray'}} 
          >{missionStatements[activeStep]}</Typography>

          <MobileStepper
          variant="dots"
          steps={STEP_COUNT}
          position="static"
          activeStep={activeStep}
          sx={{backgroundColor: 'transparent', alignSelf:'center', }}
          nextButton={<Button />}
          backButton={<Button />}
          />            
          </Box>


        <Box sx={{ width:'100%', background:'white'}}>
          <LandingPageDemo scrollPosition={scrollPositionRef.current}/>  
        </Box>  

        <RegisterPanel  context={"Default"} />

      </Box>
    );


}

export default LandingPage