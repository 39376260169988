import * as React from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { getRowIdFromRowModel } from '@mui/x-data-grid/internals';
import { Box, Button, Dialog, DialogContent, Paper, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import OrderTable from './OrderTable';
import Title from './Title';
import { TransitionProps } from '@mui/material/transitions';
import useWindowDimensions from './useWindowDimensions';
import { EquityOrderType, ErrorLogType, projectMembershipType } from './Interfaces';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../firebase';
import { addDoc, collection, getDocs, query, where } from 'firebase/firestore';
import EquityOrder from './EquityOrder';
import useState from 'react-usestateref';


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
    children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function MarketView({projectId, currency, ProjectsMemberships}:{projectId:string, currency:string, ProjectsMemberships:projectMembershipType[]}) {    

    const [user, loading, error] = useAuthState(auth);
    let currentUserId = user?.uid;
    let currentUserName = user?.displayName??"";

    const [openCreateOrderPanel, setopenCreateOrderPanel] = React.useState(false);
    const [openBuySharesPanelOrderId, setopenBuySharesPanelOrderId] = React.useState<string>("");

    const [statusText, setstatusText, statusTextRef] = useState<string>("");

    const [EquityOrders, setEquityOrders] = React.useState<EquityOrderType[]>();

    async function ErrorHandler(error:Error, SourceFunction:string)
    {
      let errorLog:ErrorLogType = {file: "MarketView", message: error.message, userId:currentUserId??"", date:new Date(), sourceFunction: SourceFunction, }
      await addDoc(collection(db, "ErrorLogs"), errorLog );
      setstatusText("We're sorry .. something went wrong.");    
    }

    function loadDB(){
        const fetchMarketOrders = async () => {
          const q = query(collection(db, "EquityOrders"), where("projectId", "==", projectId), where("status", "==", "Pending"));
          const querySnapshot = await getDocs(q);
            let MarketOrders:EquityOrderType[]=[];
            querySnapshot.docs.forEach(doc=>{
              let order:EquityOrderType;
              order=doc.data();
              order.id = doc.id;
              order.isDirty = false;
              MarketOrders.push(order);
            })
            setEquityOrders(MarketOrders);
          }
          fetchMarketOrders().catch((e)=>{ErrorHandler(e, "Fetch MarketOrders")});  
    
      }

      React.useEffect(
        ()=>{
            loadDB();
    },[]);


    return (
        <Paper elevation={0} 
        sx={{
            py: 2,
            my: 2,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 5,
            alignItems:'center',
        }}>
  
        <Title >Equity Market</Title>

        <Typography color={"blue"} sx={{fontSize:12, pb:1, minHeight:25}} >{statusText}</Typography>

        <OrderTable EquityOrders={EquityOrders!} 
                    statusRef={statusTextRef} 
                    setStatusFn={setstatusText} 
                    setopenBuySharesPanelOrderId={setopenBuySharesPanelOrderId}
                    currency={currency}/>

        <Button sx={{textTransform: 'none', fontSize:12, minWidth:'15%', mt:2}} variant="contained" onClick={() =>{setopenCreateOrderPanel(true)}}>Create an Order</Button>

        <Dialog
            maxWidth="lg"
            open={openCreateOrderPanel}
            TransitionComponent={Transition}
            onClose={()=>{setopenCreateOrderPanel(false); loadDB();}}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogContent>
                <EquityOrder 
                projectId={projectId} 
                currency={currency}
                currentOwnership={ProjectsMemberships.filter(member=>member.status==="active").find(member=>member.userId===currentUserId)?.sharesOwned??0}
                closecallbackfn={()=>{setopenCreateOrderPanel(false); loadDB();}}/>
            </DialogContent>
        </Dialog>

        <Dialog
            maxWidth="lg"
            open={openBuySharesPanelOrderId!==""}
            TransitionComponent={Transition}
            onClose={()=>{setopenBuySharesPanelOrderId(""); loadDB();}}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogContent>
                <Typography>{openBuySharesPanelOrderId}</Typography>
            </DialogContent>
        </Dialog>


        </Paper>
      );
    }