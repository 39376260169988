import { Box, Typography } from "@mui/material";
import Logoimage from '../Image/logo4.png'; 

export default function LogoComp()
{
    return (
        <Box sx={{alignSelf:'start', ml:'6vw', my:2, }}>
        <Box
              component="img"
              sx={{
                height: '50px',
                width: '70px',
                mb:-2,
              }}
              alt=""
              src={Logoimage}
              />
        <Typography fontFamily={'revert'}  fontWeight={'bolder'} color={'rgb(37, 150, 190)'} 
        sx={{fontSize:'min(7vw,50px)', textShadow:'1px 1px 1px', WebkitTextStroke:'0.5px black'}}>EffortFund</Typography>
        <Typography fontFamily={'fantasy'} color={'black'} fontWeight={'normal'} sx={{ fontSize:'min(2.8vw,13px)'}}>A Partnership Management Platform</Typography>
      </Box>
    );
}

