import { Box, Button, FormControlLabel, FormGroup, Paper, Slider, Switch, ToggleButton, ToggleButtonGroup, Tooltip, Typography, colors } from "@mui/material";
import { ResponsivePie } from "@nivo/pie";
import { EquityComponentType, SkillType } from "./Interfaces";
import Title from "./Title";
import React, { useEffect } from "react";
import useState from 'react-usestateref';
import useWindowDimensions from "./useWindowDimensions";


export default function TargetEquityChart({equityComps, setEquityCompFocus, authorizedShares, IncreaseEquityCallback, ToggleLockCallback, ResetEquitiesCallback, StepTooLargeError}:
    {equityComps:EquityComponentType[], setEquityCompFocus:Function,  authorizedShares:number, IncreaseEquityCallback:Function, ToggleLockCallback:Function, ResetEquitiesCallback:Function, StepTooLargeError:boolean}) {
    

    const [step, setStep] = useState<number>(0);
    const [IncrementDecrement, setIncrementDecrement] = useState<string>("Increment");
    const [SharesPercentages, setSharesPercentages] = useState<string>("Shares");


    let clickCount=0;

    function chartClickHandler(data:any, event:any)
    {

        if(clickCount===0)
        {
            setTimeout(() => {
    
                if(clickCount===1)
                {
                    if(IncrementDecrement=="Increment")
                    IncreaseEquityCallback(data.id, step);
                    else if(IncrementDecrement=="Decrement")
                    IncreaseEquityCallback(data.id, step*-1);
                }
                else if(clickCount===2)
                {
    
                    ToggleLockCallback(data.id);
                }
                else
                {
                }
                clickCount=0;
              }, 200);    

        }
        clickCount++;
        console.log(clickCount)
    }

    const marks = [
        {
          value: 0,
          label: ((equityComps.filter((comp)=>(comp.locked==false))).length-1).toString(),
        },
        {
          value: 1,
          label: (((equityComps.filter((comp)=>(comp.locked==false))).length-1)*10).toString(),
        },
        {
          value: 2,
          label: (((equityComps.filter((comp)=>(comp.locked==false))).length-1)*100).toString(),
        },
        {
          value: 3,
          label: (((equityComps.filter((comp)=>(comp.locked==false))).length-1)*1000).toString(),
        },
        {
          value: 4,
          label: (((equityComps.filter((comp)=>(comp.locked==false))).length-1)*10000).toString(),
        }
      ];
      

    return (

        <Paper elevation={0} 
        
        sx={{
            py: 2,
            my: 2,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 5,
            width: '100%',

            //alignItems:"center"
        }}>

            <Typography variant="caption" sx={{textAlign:"center"}}>Click on a component to increase or decrease its allocated equity.</Typography>
            <Typography variant="caption" sx={{textAlign:"center"}}>Double click a component to freeze its allocated equity.</Typography>
            {StepTooLargeError&&<Typography variant="caption" sx={{color:'red', textAlign:"center",  height:'20px'}}>Step is too large</Typography>}
            {!StepTooLargeError&&<Typography variant="caption" sx={{color:'red', textAlign:"center",  height:'20px'}}> </Typography>}

            <Box width='25%' alignSelf={"center"} sx={{mt:2}}>

            <Tooltip title="Step" enterTouchDelay={0} leaveTouchDelay={5000} arrow>
                <Slider
                    aria-label="Shares Step"
                    defaultValue={0}
                    value={step}
                    onChange={(e,value)=>{setStep(value as number)}}
                    step={null}
                    valueLabelDisplay="off"
                    marks={marks}
                    min={0}
                    max={4}
                /> 
                
                </Tooltip>
            </Box>

            <Box width='25%' alignSelf={"center"} sx={{display:"flex", flexDirection:'row', mt:2, justifyContent:'space-evenly'}}>

                <ToggleButtonGroup
                sx={{}}
                size="small"
                value={IncrementDecrement}
                onChange={(event, value)=>{
                    if(value!==null)
                    {setIncrementDecrement(value)}
                }}
                aria-label="text formatting"
                exclusive
                orientation= {"horizontal"}
                >
                    <ToggleButton sx={{fontSize:'min(2.8vw,15px)'}} value="Increment">+</ToggleButton>
                    <ToggleButton sx={{fontSize:'min(2.8vw,15px)'}} value="Decrement" >-</ToggleButton>
                </ToggleButtonGroup>

                <ToggleButtonGroup
                sx={{}}
                size="small"
                value={SharesPercentages}
                onChange={(event, value)=>{
                    if(value!==null)
                    {setSharesPercentages(value)}
                }}
                aria-label="text formatting"
                exclusive
                orientation= {"horizontal"}
                >
                    <ToggleButton sx={{fontSize:'min(2.8vw,15px)', textTransform: 'none'}} value="Shares" >Shares</ToggleButton>
                    <ToggleButton sx={{fontSize:'min(2.8vw,15px)', textTransform: 'none'}} value="Percentages">%</ToggleButton>
                </ToggleButtonGroup>

                <Button sx={{textTransform: 'none', color:'black', backgroundColor:'#DCDCDC'}} variant="contained" onClick={()=>ResetEquitiesCallback()}>Reset</Button>

            </Box>
            

            <Box sx={{height:350}} >

                <ResponsivePie
                data={equityComps.map(comp=>{
                     return {"id":comp.name,"label":comp.name,
                     "value":SharesPercentages=="Shares"?(comp.targetEquityShares!):(comp.targetEquityShares!/(authorizedShares/100)).toFixed(1), 
                     "locked":comp.locked}   
                 })
                }
                onClick={chartClickHandler}
                activeId={equityComps.find(comp=>(comp.focus==true))?.name}
                onActiveIdChange={(id)=>{console.log(id); setEquityCompFocus(id);}}
                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                activeInnerRadiusOffset={50}
                activeOuterRadiusOffset={20}
                colors={{ scheme: 'set3' }}
                borderWidth={3}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            0.8
                        ]
                    ]
                }}
                tooltip={()=><div></div>}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: 'color' }}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            2
                        ]
                    ]
                }}

                defs={[
                    {
                        id: 'dots',
                        type: 'patternDots',
                        background: 'inherit',
                        color: 'rgba(255, 255, 255, 0.3)',
                        size: 4,
                        padding: 1,
                        stagger: true
                    },
                    {
                        id: 'lines',
                        type: 'patternLines',
                        background: 'inherit',
                        color: 'rgba(255, 255, 255, 0.9)',
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 10
                    }
                ]}
                fill={
                        equityComps.filter((element)=>(element.locked===true))?.map((element)=>
                        ({
                            match: {
                                id: element.name
                            },
                            id: 'lines'
                        })
                        )
                    }
                
                legends={[
                    {
                        anchor: 'bottom',
                        direction: 'row',
                        justify: false,
                        translateX: 0,
                        translateY: 56,
                        itemsSpacing: 0,
                        itemWidth: 100,
                        itemHeight: 18,
                        itemTextColor: '#999',
                        itemDirection: 'left-to-right',
                        itemOpacity: 1,
                        symbolSize: 18,
                        symbolShape: 'circle',
                        
                        /*effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000'
                                }
                            }
                        ]*/
                    }
                ]}
            />
        </Box>
    </Paper>    
    );
}