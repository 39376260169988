import { Box, Fab, Tooltip } from "@mui/material";
import CloudIcon from '@mui/icons-material/Cloud';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import ReplayIcon from '@mui/icons-material/Replay';
import { useState } from "react";
import { CloudStatusType } from "./Interfaces";
import React from "react";
import { stat } from "fs";


export default function CloudStatus({statusRequest, errorDescRequest}:{statusRequest:CloudStatusType, errorDescRequest: string}) {


    const [status, setStatus] = React.useState<CloudStatusType>("None");
    const [errorDesc, setErrorDesc] = React.useState<string>("");


    React.useEffect(() =>{
        if(status !== "Error")
        {
            setStatus(statusRequest);
        }

        if(statusRequest=="Error")
        {
            setErrorDesc(errorDescRequest);
            console.log(errorDescRequest);
        }


    },
    [statusRequest]
    );

    return (
    <Box>

        {status=="None" &&
        <Fab sx={{mx:3, mb:2}} size='small' color="inherit" aria-label="add">
            <CloudIcon/>
        </Fab>
        }


        {status=="OK" &&
        <Fab sx={{mx:3, mb:2}} size='small' color="success" aria-label="add">
            <CloudDoneIcon/>
        </Fab>
        }


        {status=="Error" &&
        <Tooltip title={errorDesc} enterTouchDelay={0} leaveTouchDelay={5000} arrow>
        <Fab sx={{mx:3, mb:2}} size='small' color="error" aria-label="add" >
            <CloudOffIcon/>
        </Fab>
        </Tooltip>
        }


        {status=="Sync" &&
        <Fab sx={{mx:3, mb:2}} size='small' color="info" aria-label="add">
            <CloudSyncIcon/>
        </Fab>
        }

    </Box>

    );

}
