import React from "react";
import { EquityOrderType, ErrorLogType, projectMembershipType } from "./Interfaces";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase";
import { addDoc, collection } from "firebase/firestore";
import { Box, Button, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import useWindowDimensions from "./useWindowDimensions";


export default function EquityOrder({projectId, currency, currentOwnership, closecallbackfn}:{projectId:string, currency:string, currentOwnership:number, closecallbackfn:()=>void}) {    

    const [user, loading, error] = useAuthState(auth);
    let currentUserId = user?.uid;
    let currentUserName = user?.displayName??"";
    const [statusText, setstatusText] = React.useState<string>("");

    
    const [newEquityOrder, setnewEquityOrder] = React.useState<EquityOrderType>(
        {
            userId:currentUserId,
            projectId:projectId,
            type:"Buy",
            shares:0,
            price:0,
            status:"Pending",
        }
    );



    async function SubmitEquityOrder(){
        if(newEquityOrder?.shares===0 || newEquityOrder?.price===0)
        {
            setstatusText("Please enter a valid share count and price.");
        }
        else if(((newEquityOrder?.shares??0)>currentOwnership) && newEquityOrder?.type==="Sell")
        {
            setstatusText("You can't sell more shares than you own.");
        }
        else
        {
            await addDoc(collection(db, "EquityOrders"), newEquityOrder ).then(()=>{closecallbackfn();}).catch((e)=>{ErrorHandler(e, "Add Equity Order");});
        }
    }

    async function ErrorHandler(error:Error, SourceFunction:string)
    {
      let errorLog:ErrorLogType = {file: "EquityOrder", message: error.message, userId:currentUserId??"", date:new Date(), sourceFunction: SourceFunction, }
      await addDoc(collection(db, "ErrorLogs"), errorLog );
      setstatusText("We're sorry .. something went wrong.");    
    }

    React.useEffect(
        ()=>{
            setstatusText("");
            setnewEquityOrder({
                userId:currentUserId,
                username:currentUserName,
                projectId:projectId,
                type:"Buy",
                shares:0,
                price:0,
                status:"Pending",
            }
    )
    },[]);


    return(

        <Box sx={{display:'flex', flexDirection:'column', alignItems:'center'}}>

        <ToggleButtonGroup
        sx={{mb:4}}
        value={newEquityOrder?.type}
        onChange={(event, values)=>{
            if(values!==null)
            {setnewEquityOrder({...newEquityOrder, type:values})}
        }}
        aria-label="text formatting"
        exclusive
        orientation= {(useWindowDimensions().width<800)? "vertical":"horizontal"}
        >
            <ToggleButton sx={{fontSize:'min(2.5vw,10px)'}} value="Buy">Buy Order</ToggleButton>
            <ToggleButton sx={{fontSize:'min(2.5vw,10px)'}} value="Sell" >Sell Order</ToggleButton>
        </ToggleButtonGroup>

        <Typography color={"blue"} sx={{fontSize:12, pb:1}} >{statusText}</Typography>


        <TextField
        sx={{m:1, minWidth:'30vw'}}
        label={"Shares Count [Current position: "+currentOwnership+" shares]"}
        value={newEquityOrder?.shares}
        onChange={(e) => {
            if(parseInt(e.target.value)>=0)
            {
                setnewEquityOrder({...newEquityOrder, shares:parseInt(e.target.value)});
            }
            else
            {
                setnewEquityOrder({...newEquityOrder, shares:0});
            }
        }}
        />

        <TextField
        sx={{m:1, minWidth:'30vw'}}
        label={"Price per share ("+currency+')'}
        value={newEquityOrder?.price}
        onChange={(e) => {
            if(parseInt(e.target.value)>=0)
            {
                setnewEquityOrder({...newEquityOrder, price:parseInt(e.target.value)});
            }
            else
            {
                setnewEquityOrder({...newEquityOrder, price:0});
            }
        }}
        />

        <Button sx={{textTransform: 'none', fontSize:12, minWidth:'15%', mt:2}} variant="contained" onClick={SubmitEquityOrder}>Submit</Button>


    </Box>

    );
}